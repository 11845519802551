.stressful {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  width: 430px;
  max-width: 100%;
  min-height: 150px;
  padding: 60px 20px;
  border: 2px solid var(--game-color);
  background-color: rgba(#000000, 0.5);
  border-radius: 16px;
}

.title {
  text-align: center;
  font-size: 20px;
  margin: 0 0 32px;
  color: white;
  font-weight: 700;
}

.center {
  margin: 0 auto;
  text-align: center;
}

.flex {
  display: flex;
  justify-content: space-evenly;
}

.btn {
  width: 160px;
  height: 56px;
  color: #000;
  font-weight: 700;
  background-color: var(--game-color);
  font-size: 20px;
  text-align: center;
  border: none;
  border-radius: 16px;
  cursor: pointer;

  &:hover,
  &:active {
    opacity: 0.5;
  }
}
